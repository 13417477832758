<template>
  <kn-table 
    :headers="headers" 
    :items="items"
    :loading="loading"
    tableTitle="Productos" 
    newButtonLabel="Agregar producto"
  />
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { api } from '@/api/axios-base'
import KnTable from '@/components/tables/KnTable.vue'
export default {
  components: { KnTable },
  data() {
    return {
      groups: [],
      products: [],
      headers: [
        { text: 'SKU', value: 'sku', class: 'purple--text' },
        { text: 'Nombre', value: 'nombre_producto', class: 'purple--text' },
        { text: 'Descripción', value: 'descripcion_corta', class: 'purple--text' },
        { text: 'Marca', value: 'marca.dato', class: 'purple--text' },
        { text: 'Precio', value: 'precio', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      search: null,
      loading: true
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse'])
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    await this.fetchProductsByEI(this.institutionId)
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async fetchProductsByEI(institutionId) {
      try {
        const response = await api.get(`/productos/api/producto-by-ie/?id_institucion=${institutionId}`)
        const resposeData = await response.data
        // console.log('Respuesta productos: ', resposeData);
        this.products = resposeData
        for (const product of this.products) {
          const priceProduct = product.precios.length 
                                ? product.precios.find(price => price.distribuidor === institutionId) 
                                : null
          
          const inventoryAll = await this.fetchInventoryByProduct(product.id)
          const inventory = inventoryAll['lista_inventarios producto'].find(item => item.almacen.id === this.warehouse.id)
          
          const item = {
            precio: priceProduct ? `$${priceProduct.precio_distribuidor}` : "$0.00",
            inventario: inventory ? inventory : null,
            ...product
          }
          this.items.push(item)
        }
      } catch (error) {
        console.error('Error al obtener productos. ', error);
      }
    },
    async fetchInventoryByProduct(productId) {
      try {
        const response = await api.post('/inventarios/inventarios-producto', {
          id_producto: productId
        })
        const responseData = await response.data
        // console.log('inventario de producto?', responseData);
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener inventario');
      }
    }
  }
}
</script>

<style>
</style>